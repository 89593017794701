import { useState, useEffect } from "react";
import { flushSync } from "react-dom";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getMediaHideEdit,
  getMediaFile,
} from "../../../../../services/mediaMgt";
import { subOnProcessedMedia } from "../../../../../graphql/api";
import {
  updateMMData,
  createMMData,
  deleteMMData,
} from "../../../../../slices/mediaMgtSlice";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import SbuxSubTabs from "../../../../../components/SbuxTabs/SbuxSubTabs";
import SbuxSnackbars from "../../../../../components/SbuxSnackbars";
import MediaMgtRow from "./MediaMgtRow";
import MediaMgtDialog from "../../common/MediaMgtDialog";
import { columns, filterColumns } from "../mediaMgt/columns";
import { MEDIAMGT_REFRESH_USER_MESSAGE } from "../../../../../constants/index";
import { logMessage } from "../../../../../utils/amplifyLogger";
import { getInstanceIdSelector } from "../../../../../selectors/userInstanceSelector";
import { getCognitoUserIdSelector } from "../../../../../selectors/authSelector";
import {
  getMediaContentSelector,
  getIsEditOpenSelector,
  getEditDataSelector,
} from "../../../../../selectors/mediaMgtSelector";

const MediaMgtTabs = ({
  mediaMgtData,
  languages,
  handleRefrehClick,
  filteredItem,
  setFilteredItem,
  mediaMgtReset,
}) => {
  const [languageTabId, setLanguageTabId] = useState("all");
  const [openAddNew, setOpenAddNew] = useState(false);
  const [mediaMgtFilterData, setMediaMgtFilterData] = useState([]);
  const [updatedMediaId, setUpdatedMediaId] = useState(null);
  const [openMMUpdateSnackbar, setOpenMMUpdateSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const mediaContent = useSelector(getMediaContentSelector);
  const isEditOpen = useSelector(getIsEditOpenSelector);
  const editData = useSelector(getEditDataSelector);

  const cognitoUserId = useSelector(getCognitoUserIdSelector);
  const instanceId = useSelector(getInstanceIdSelector);
  const dispatch = useDispatch();

  const CREATE_OPERATION = "CREATE";
  const UPDATE_OPERATION = "UPDATE";
  const DELETE_OPERATION = "DELETE";
  const subs = [];

  const handleMediaSubscription = (data) => {
    const mediaData = data?.data?.onProcessedMedia;
    if (mediaData?.lastUpdatedBy !== cognitoUserId) {
      setUpdatedMediaId(mediaData?.mediaId);
      const operation = mediaData.__operation;
      switch (operation) {
        case CREATE_OPERATION:
          dispatch(createMMData(mediaData));
          break;
        case UPDATE_OPERATION:
          dispatch(updateMMData(mediaData));
          /* eslint-disable no-case-declarations */
          const mediaFileData = {
            ...mediaData,
            type: "Custom",
            fileName: btoa(mediaData.filePath) ?? btoa(mediaData.fileName),
          };
          /* eslint-enable no-case-declarations */
          dispatch(getMediaFile(mediaFileData));
          break;
        case DELETE_OPERATION:
          dispatch(deleteMMData(mediaData));
          break;
      }
      setOpenMMUpdateSnackbar(true);
    }
  };

  useEffect(() => {
    subs.forEach((sub) => sub.unsubscribe());

    flushSync(() => {
      const onProcessSub = subOnProcessedMedia(instanceId).subscribe(
        handleMediaSubscription,
        (err) =>
          logMessage(
            `Media Management `,
            `Something went wrong in Subscription API => ${err}`,
            `error`
          )
      );

      subs.push(onProcessSub);
    });

    return () => {
      subs.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (filteredItem && filteredItem.length > 0) {
      const filteredData = mediaMgtData.data?.reduce((acc, cur) => {
        const curValues = Object.values(cur);
        const isExists = curValues.some((f) => filteredItem.includes(f));
        if (isExists) {
          acc.push(cur);
        }
        return acc;
      }, []);
      setMediaMgtFilterData(filteredData);
    }
    handleSubTabChange(languageTabId);
  }, [mediaMgtData, filteredItem]);

  const handleAddNew = () => {
    setOpenAddNew((prevState) => !prevState);
  };

  const handleSubTabChange = (newValue) => {
    setLanguageTabId(newValue);
    let data = [];
    if (filteredItem && !filteredItem?.includes("all")) {
      data =
        newValue !== "all"
          ? mediaMgtData.data.filter(
              (f) =>
                f.languageCode === newValue && filteredItem?.includes(f.type)
            )
          : mediaMgtData.data.filter((f) => filteredItem?.includes(f.type));
    } else {
      data =
        newValue !== "all"
          ? mediaMgtData.data.filter((f) => f.languageCode === newValue)
          : mediaMgtData.data;
    }
    setMediaMgtFilterData(data);
  };

  const getLanguageList = () => {
    return languages && languages.length > 0
      ? languages.reduce((acc, cur) => {
          acc.push({
            name: cur.name,
            value: cur.code,
          });
          return acc;
        }, [])
      : [];
  };
  const closeEdit = () => {
    dispatch(getMediaHideEdit());
  };
  const handleClose = () => {
    setOpenMMUpdateSnackbar(false);
    const updatedData = mediaMgtFilterData.map((item) => {
      return { ...item, highLight: false };
    });
    setMediaMgtFilterData(updatedData);
  };

  const SubTabsComponent = () => {
    let languageList = getLanguageList();

    if (languageList.length > 0) {
      const defaultLanguage = [
        {
          name: "All",
          value: "all",
        },
      ];
      languageList = [...defaultLanguage, ...languageList];
    }

    const handleSubTabClick = (tab) => {
      handleSubTabChange(tab.value);
    };

    return (
      <SbuxSubTabs
        tabList={languageList}
        tabId={languageTabId}
        handleTabClick={handleSubTabClick}
      />
    );
  };

  return (
    <Paper>
      <SbuxEnhancedTable
        rows={
          mediaMgtReset && filteredItem.length === 0 ? [] : mediaMgtFilterData
        }
        columns={columns}
        defaultSortingField={"promptName"}
        defaultSortOrder={"asc"}
        RowSource={MediaMgtRow}
        showFooter={true}
        showSearchBar={true}
        isManager={true}
        filterColumns={filterColumns}
        defaultFilterColumns={filteredItem}
        setFilteredItem={setFilteredItem}
        filterTitle={<SbuxTranslate>{"Media Management Filter"}</SbuxTranslate>}
        showSubTabs
        showAddNewButton
        handleAddNewButtonClick={handleAddNew}
        addNewButtonTitle={<SbuxTranslate>{"Add new"}</SbuxTranslate>}
        SubTabs={SubTabsComponent}
        handleRefrehClick={handleRefrehClick}
        updatedRowId={updatedMediaId}
        isLoading={isLoading}
      />
      {openAddNew && (
        <MediaMgtDialog
          title={<SbuxTranslate>{`Create a new audio file`}</SbuxTranslate>}
          open={openAddNew}
          toggleDialogOpen={handleAddNew}
          languages={getLanguageList()}
          isAddNew
        />
      )}
      {isEditOpen && (
        <MediaMgtDialog
          title={<SbuxTranslate>{`Edit audio file`}</SbuxTranslate>}
          open={isEditOpen}
          toggleDialogOpen={closeEdit}
          row={editData}
          mediaContent={mediaContent}
          isAddNew={false}
        />
      )}
      {openMMUpdateSnackbar && (
        <SbuxSnackbars
          width={380}
          open={openMMUpdateSnackbar}
          message={MEDIAMGT_REFRESH_USER_MESSAGE}
          autoHide={false}
          handleClose={handleClose}
        />
      )}
    </Paper>
  );
};

export default MediaMgtTabs;
